<template>
	<div class="c-app">
		<CWrapper>
			<TheHeader />
			<div class="c-body">
				<main class="c-main">
					<CContainer fluid>
						<transition name="fade" mode="out-in">
							<router-view :key="$route.path"></router-view>
						</transition>
					</CContainer>
				</main>
			</div>
			<footer class="c-footer bg-white">
				<div id="container-floating" style="">
					<div class="nd3 nds" data-toggle="tooltip" data-placement="left" data-original-title="WhatsApp">
						<a href="https://wa.me/6281233035559" target="_blank">
							<p class="reminder"><span class="mdi mdi-whatsapp"></span></p>
						</a>
					</div>
					<div class="nd1 nds" data-toggle="popover" data-placement="left" data-title="Phone" data-content="+62 812 3303 5559" data-original-title="" title=""><img class="reminder">
						<a href="tel:+6281233035559" target="_blank">
							<p class="letter"><span class="mdi mdi-deskphone"></span></p>
						</a>
					</div>

					<router-link :to="{name: 'PusatBantuan'}" id="floating-button" 
						v-c-popover="{
						header: false,
				        content: 'Pusat Bantuan',
				        active: true,
				        placement: 'right-end'
				      }"
				    >
						<p class="plus"><i class="fa fa-question"></i></p>
					</router-link>
				</div>
				<div class="container">
					<CRow>
						<div class="col-6">
							<div><a href="#" target="_blank">PPVTPP</a><span class="ml-1">&copy; {{new Date().getFullYear()}} Kementrian Pertanian.</span></div>
						</div>
						<div class="col-6">
							<div class="mfs-auto text-right"><span class="mr-1">Powered by</span><a href="https://perizinan.pertanian.go.id/" target="_blank">Pusat Perlindungan Varietas Tanaman dan Perizinan Pertanian</a></div>
						</div>
					</CRow>
				</div>
				
			</footer>
		</CWrapper>
	</div>
</template>

<script>
	import TheHeader from "./TheHeader";

	export default {
		name: "TheContainer",
		components: {
			TheHeader,
		},
		methods: {
			
		},
		
		
	};
</script>

<style scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
