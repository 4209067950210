<template>
	<CHeader fixed with-subheader light>
		<div class="container">
			<div class="row">
				<div class="col-md-12 bg-white">
					<nav class="navbar navbar-expand-sm navbar-light w-100">
						<a class="navbar-brand" to="/"><img src="img/logo-kementan.png" style="width: 60px;" alt=""></a><b>Kementrian Pertanian <br> Republik Indonesia</b>
						<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>
						<div class="collapse navbar-collapse " id="navbarSupportedContent">
							<template>
								<ul class="navbar-nav ml-auto" v-if="is_edit">
									<li class="nav-item">
										<template>
											<CDropdown :toggler-text="session_nama" class="nav-link py-0" >
												<CDropdownItem :to="{name: 'ProfilePemohon'}"><i class="fa fa-user"> &nbsp;</i> &nbsp;Profile</CDropdownItem>
												<CDropdownItem :to="{name: 'LogoutPemohon'}"><i class="fa fa-sign-out"> &nbsp;</i> &nbsp;Logout</CDropdownItem>
											</CDropdown>
										</template>
									</li>
								</ul>
								<ul class="navbar-nav ml-auto" v-else>
									<li class="nav-item">
										<router-link class="nav-link" :to="{name: 'DashboardPemohon'}">Dashboard</router-link>
									</li>
									<li class="nav-item">
										<template>
											<CDropdown toggler-text="Permohonan" class="nav-link py-0" >
												<CDropdownItem :to="{name: 'AjukanPermohonan'}" v-if="is_open">Ajukan Permohonan</CDropdownItem>
												<CDropdownItem :to="{name: 'PermohonanPemohon',params: {status : 'menunggu perbaikan'}}">Menunggu Perbaikan</CDropdownItem>
												<CDropdownItem :to="{name: 'PermohonanPemohon',params: {status : 'proses'}}">Proses</CDropdownItem>
												<CDropdownItem :to="{name: 'PermohonanPemohon',params: {status : 'selesai'}}">Selesai</CDropdownItem>
												<CDropdownItem :to="{name: 'PermohonanPemohon',params: {status : 'dibatalkan'}}">Dibatalkan</CDropdownItem>
											</CDropdown>
										</template>
									</li>
									<li class="nav-item">
										<template>
											<CDropdown toggler-text="Laporan" class="nav-link py-0" >
												<CDropdownItem :to="{path: '/pemohon/laporan/permohonan-selesai'}">laporan Permohonan Selesai</CDropdownItem>
												<CDropdownItem :to="{path: '/pemohon/laporan/realisasi'}">laporan Realisasi</CDropdownItem>
											</CDropdown>
										</template>
									</li>
									<li class="nav-item">
										<router-link class="nav-link" :to="{name: 'InformasiKontak'}">Informasi Kontak</router-link>
									</li>
									<li class="nav-item">
										<template>
											<CDropdown class="nav-link py-0" >
								                <template #toggler>
								                    <CHeaderNavLink class="nav-link dropdown-toggle no-caret">
								                        <i class="fa fa-bell"></i>
								                        <span class="label notif-pemohon bg-info text-white border-radius-50">{{countnotif}}</span>
								                    </CHeaderNavLink>
								                </template>
								                <CDropdownHeader tag="div" class="text-center" color="light">
								                    <strong>Permohonan Masuk</strong>
								                </CDropdownHeader>
								                <div v-for="(value_list_notif, key_list_notif) in list_notif" :key="key_list_notif">
									                <CDropdownItem v-on:click="readMessage(value_list_notif.id, value_list_notif.data.offer_id, value_list_notif.data.message.offerUrl)"> {{value_list_notif.data.message.body}} </CDropdownItem>
									                <CDropdownDivider />
								                </div>
								            </CDropdown>
										</template>
									</li>

									<li class="nav-item">
										<template>
											<CDropdown :toggler-text="session_nama" class="nav-link py-0" >
												<CDropdownItem :to="{name: 'ProfilePemohon'}"><i class="fa fa-user"> &nbsp;</i> &nbsp;Profile</CDropdownItem>
												<CDropdownItem :to="{name: 'LogoutPemohon'}"><i class="fa fa-sign-out"> &nbsp;</i> &nbsp;Logout</CDropdownItem>
											</CDropdown>
										</template>
									</li>

								</ul>

							</template>
							
						</div>
					</nav>
				</div>
			</div>
		</div> 
	</CHeader>
</template>
<style>
		.c-main{
			padding-top:0px !important;
		}
	</style>
<script>
	const null_array = [];
	export default {
		name: "TheHeader",
		data() {
            return {
                session_nama: this.session_nama,
                session_data: JSON.parse(this.session),
                countnotif: 0,
                list_notif: null_array,
                is_edit: false,
                is_open: false,
            };
        },
        methods: {
            readMessage(id_notif, id_permohonan, link_permohonan){
            	Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
						text: "Mohon tunggu sebentar...",
						showConfirmButton: false,
					allowOutsideClick: false,
				});
            	let self = this;
            	axios
	                .get(this.apiLink + "api/mark-notification", {
	                    params:{
	                        id: id_notif
	                    },
	                    headers: {
	                        Authorization: "Bearer " + this.access_token,
	                    },
	                })
	                .then((response) => {
	                    var res_count = response.data;
	                    var res_count_data = res_count.data;
	                    
	                    if (res_count.meta.code != "200") {
	                        Swal.fire({
	                            icon: "error",
	                            title: "Oops...",
	                            text: res_count.data.message,
	                        });
	                    } else {
	                    	if (link_permohonan.includes('pemasukan')) {
		                    	self.$router.push({name: 'EditPermohonanPemasukanPemohon', params : {idpermohonan: id_permohonan}});
	                    	}
	                    	else{
	                    		self.$router.push({name: 'EditPermohonanPengeluaranPemohon', params : {idpermohonan: id_permohonan}});
	                    	}
	                    }
	                });
            }
        },
        created(){
        	// console.log(this.access_token);
        	if (this.session_data.nib == null) {
                this.is_edit = true;
            }
        	axios
                .get(this.apiLink + "api/permohonan/counting", {
                    params:{
                        nib: this.session_data.nib 
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_count = response.data;
                    var res_count_data = res_count.data;
                    
                    if (res_count.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_count.data.message,
                        });
                    } else {
                        this.countnotif = res_count_data.selesai + res_count_data.menunggu_perbaikan;
                    }
                });
            // Get list Notification
            axios
                .get(this.apiLink + "api/get-notification", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_list = response.data;
                    var res_list_data = res_list.data;
                    
                    if (res_list.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_list.data.message,
                        });
                    } else {
                    	// console.log(res_list_data);
                        this.list_notif = res_list_data;
                    }
                });
            let self = this;
            axios.get(this.apiLink + "api/crud/konfigurasi", {
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_konfigurasi = response.data;
                var res_konfigurasi_data = res_konfigurasi.data[0];
                // console.log(res_konfigurasi_data);
                if (res_konfigurasi.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_konfigurasi.data.message
                    });
                }
                else{
                    
                    var open_apli = res_konfigurasi_data.find(options => {
                        if (options.id === 1) {
                            return options
                        }
                    });
                    if (open_apli.k_aksi != 'open') {
                        self.is_open = true;
                    }
                    

                }
            });
        },
		mounted() {
			// if (localStorage.getItem("roles") === null) {
			// 	if (this.$router.currentRoute.name !== "Home") {
			// 		this.$router.push({ name : 'Home' })
			// 	}
			// }else{
			// 	if (this.$router.currentRoute.name !== localStorage.redirectTo) {
			// 		this.$router.push({ name : localStorage.redirectTo })
			// 	}
			// }

			
		}
	};
</script>